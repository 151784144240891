:root {
  --blanco: rgba(255, 255, 255, 1);
  --negro: rgba(0, 0, 0, 1);
  --azul: rgba(3, 44, 86, 1);
  --azul1: rgba(11, 69, 128, 1);
  --azul2: rgba(7, 56, 108, 1);
  --azul3: rgba(2, 30, 60, 1);
  --azul4: rgba(1, 14, 30, 1);
  --rojo: rgba(229, 27, 34, 1);
  --rojo1: rgba(250, 115, 120, 1);
  --rojo2: rgba(241, 73, 77, 1);
  --rojo3: rgba(181, 10, 16, 1);
  --rojo4: rgba(143, 0, 3, 1);
  --gris: rgba(217, 217, 217, 1);
  --grisSuave: rgba(217, 217, 217, 0.3);
  --grisInput: rgba(217, 217, 217, 1);
  --grisTextInput: rgba(114, 114, 114, 1);
  --grisOscuro: rgba(55, 58, 60, 1);
  --grisClaro: rgba(84, 95, 113, 1);
  --gris15: rgba(0, 0, 0, 0.15);
  --gris30: rgba(0, 0, 0, 1);
  --gris60: rgba(0, 0, 0, 0.6);
  --gris80: rgba(0, 0, 0, 0.8);
  --grisSeparatorLine: rgba(255, 255, 255, 0.2);
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 700;
  --black: 900;
  --italic: italic;
}

html {
  color: var(--blanco);
  font-family: "Satoshi-Regular";
}
