.loaderWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid var(--negro);
  border-top: 4px solid var(--blanco);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
